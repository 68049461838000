// extracted by mini-css-extract-plugin
export var desktopNav = "desktop-nav-module--desktopNav--3m03n";
export var languageList = "desktop-nav-module--languageList--2SQO2";
export var navTop = "desktop-nav-module--navTop--3zoxj";
export var languageNav = "desktop-nav-module--languageNav--eytF0";
export var navBottom = "desktop-nav-module--navBottom--17dYp";
export var mainMenu = "desktop-nav-module--mainMenu--2RQUG";
export var ctaMenu = "desktop-nav-module--ctaMenu--2qk7l";
export var secondaryCTA = "desktop-nav-module--secondaryCTA--3P0d6 ctas-module--secondaryCTA-black--2Oln7 ctas-module--cta--1_760 ctas-module--ctaFont--2uLpm";
export var ctaList = "desktop-nav-module--ctaList--2RRTZ";
export var mainMenuList = "desktop-nav-module--mainMenuList--1Sin9";
export var mainCTA = "desktop-nav-module--mainCTA--2_JrK ctas-module--mainCTA--YgZ4V ctas-module--cta--1_760 ctas-module--ctaFont--2uLpm ctas-module--blueCTA--248xw";
export var mainNavItem = "desktop-nav-module--mainNavItem--2ncU3";
export var siteTitleLink = "desktop-nav-module--siteTitleLink--27rpW";