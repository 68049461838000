// extracted by mini-css-extract-plugin
export var root = "page-module--root--1895O";
export var title = "page-module--title--3ynpk typography-module--responsiveTitle1--2aak3";
export var mainImage = "page-module--mainImage--1cuEn";
export var grid = "page-module--grid--1vjZi";
export var mainContent = "page-module--mainContent--16Twf";
export var content = "page-module--content--3iL79";
export var metaContent = "page-module--metaContent--2Ifac";
export var publishedAt = "page-module--publishedAt--Ry1XV undefined";
export var categories = "page-module--categories--3Wf8f";
export var categoriesHeadline = "page-module--categoriesHeadline--1Au3z undefined";
export var relatedProjects = "page-module--relatedProjects--U8710";
export var relatedProjectsHeadline = "page-module--relatedProjectsHeadline--xu_8Z undefined";